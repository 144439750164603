import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function defaultState() {
	return {
		distance: 0
	}
}

const state = defaultState()

export default new Vuex.Store({
	state: state,
	mutations: {
		setDistance(state, val) {
			state.distance = val
		}
	},
	actions: {},
	getters: {}
})
<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import * as ackeeTracker from 'ackee-tracker'

export default {
	name: 'App',
	mounted() {
		window.scrollTo(0, 0)

		ackeeTracker.create('https://stats.mxis.ch', {
			detailed: true
		}).record('5a01df9d-e8e7-44a3-8a6c-b80c2616f269')
	}
}
</script>

<style>
	:root {
		--font: #fff;
		--font-light: rgb(197, 197, 197);
		--background: #191A1A;
		--background-light: #282929;
		--primary: #F24D4D;
	}

	html,
	body {
		margin: 0;
		font-family: Inter UI, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
		color: var(--font);
		background: var(--background-light);
	}
</style>